import gql from "graphql-tag";
import { USERS_INFO_FRAGMENT } from "./users";

export const GET_PRODUCTS = gql`
  query products($first: Int, $offset: Int) {
    products {
      id
    }
  }
`;

export const REQUESTS_SUBQUERY = `
  requests(first: $first, offset: $offset) {
    totalCount
    users {
      ...UsersInfo
    }
  }
`;

export const GET_PRODUCT_WITH_REQUESTS = gql`
  ${USERS_INFO_FRAGMENT}
  query product($id: ID!, $first: Int, $offset: Int) {
    product(id: $id) {
      id
      ${REQUESTS_SUBQUERY}
    }
  }
`;

export const RECENT_REQUESTS_SUBQUERY = `
  recentRequests(last: $last) {
    totalCount
    users {
      ...UsersInfo
    }
  }
`;

export const GET_PRODUCT_RECENT_REQUESTS = gql`
  ${USERS_INFO_FRAGMENT}
  query product($id: ID!, $last: Int) {
    product(id: $id) {
      id
      ${RECENT_REQUESTS_SUBQUERY}
    }
  }
`;

export const APPROVE_PRODUCT_REQUEST = gql`
  mutation approve($productId: ID!, $userId: ID!) {
    approve(productId: $productId, userId: $userId) {
      id
      pending_approval
    }
  }
`;

export const APPROVE_TEMPORARY_PRODUCT_REQUEST = gql`
  mutation approveTemporary(
    $productId: ID!
    $userId: ID!
    $secondsInFuture: Int!
  ) {
    approveTemporary(
      productId: $productId
      userId: $userId
      secondsInFuture: $secondsInFuture
    ) {
      id
      pending_approval
    }
  }
`;
