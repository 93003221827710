import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import { bindActionCreators } from "redux";

import { Router, Route, Switch } from "react-router-dom";
import { Container } from "semantic-ui-react";

import history from "../services/history";
import PrivateRoute from "./PrivateRoute";
import NavBar from "./NavBar";
import Requests from "./Requests";
import Users from "./Users";
import User from "./User";

import * as AuthActions from "../redux/auth";

import AuthCallback from "../components/AuthCallback";
import NotFound from "../components/NotFound";

import { ToastProvider } from "react-toast-notifications";

class AppRouter extends Component {
  preAuth = nextState => {
    const { handleAuthentication } = this.props;

    // console.log('preAuth nextState: ' + JSON.stringify(nextState, null, 3));

    if (/access_token|id_token|error/.test(nextState.location.hash)) {
      handleAuthentication().then(() => {
        nextState.history.replace("/");
      });
    }
  };

  render = () => {
    return (
      <Router history={history}>
        <ToastProvider>
          <NavBar />
          <Container text>
            <Switch>
              <PrivateRoute
                exact
                path="/"
                component={Requests}
                recent
                key="requests"
              />
              <PrivateRoute
                exact
                path="/requests"
                component={Requests}
                key="requests-recent"
              />
              <PrivateRoute exact path="/users" component={Users} />
              <PrivateRoute path="/users/:userId" component={User} />
              <Route
                path="/callback"
                render={props => {
                  this.preAuth(props);
                  return <AuthCallback />;
                }}
              />
              <PrivateRoute component={NotFound} />
            </Switch>
          </Container>
        </ToastProvider>
      </Router>
    );
  };
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(AuthActions, dispatch);
};

export default compose(
  connect(
    null,
    mapDispatchToProps
  )
)(AppRouter);
