// @flow

import moment from "moment";

// Auth0 Timestamp: 2019-04-18T19:30:59.274Z
const auth0Format = "YYYY-MM-DDTHH:mm:ss.SSSZ";

export const MomentAuth0Factory = input => {
  if (input == null) {
    return null;
  }

  if (input.indexOf("Z") > -1) {
    return moment(input, auth0Format);
  }

  console.error("bad date format");
  return null;
};
