import { Record, type RecordFactory } from "immutable";

import { AuthState } from "../redux/auth";
import { SupportState } from "../redux/support";

export type AppStateValues = {
  auth: AuthState,
  support: SupportState
};

const AppState: RecordFactory<AppStateValues> = Record({
  auth: AuthState(),
  support: SupportState()
});

export default AppState;
