// @flow

export function Debounce(fn: any, time: number) {
  let timeout;

  return (...args: Array<any>) => {
    const functionCall = () => fn.apply(this, args);
    clearTimeout(timeout);
    timeout = setTimeout(functionCall, time);
  };
}
