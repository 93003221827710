import React, { Component } from "react";
import { Query } from "react-apollo";
import { withToastManager } from "react-toast-notifications";

import {
  Container,
  Dimmer,
  Loader,
  Label,
  Button,
  Header,
  Icon,
} from "semantic-ui-react";

import {
  GET_PRODUCT_WITH_REQUESTS,
  GET_PRODUCT_RECENT_REQUESTS,
} from "../queries/products";
import ProductRequests from "./ProductRequests";

const LOAD_CHUNK = 25;

class Requests extends Component {
  render = () => {
    const { recent, toastManager } = this.props;
    const variables = Object.assign(
      { id: "S3C" },
      recent ? { last: LOAD_CHUNK } : { first: LOAD_CHUNK, offset: 0 }
    );

    return (
      <Query
        query={recent ? GET_PRODUCT_RECENT_REQUESTS : GET_PRODUCT_WITH_REQUESTS}
        variables={variables}
        notifyOnNetworkStatusChange
      >
        {({ loading, error, data, refetch, fetchMore, variables }) => {
          let requests =
            data &&
            data.product &&
            (data.product.requests || data.product.recentRequests);
          let total = (requests && requests.totalCount) || 0;

          const users = requests && requests.users;

          return (
            <Container>
              <Dimmer active={loading && total === 0}>
                <Loader
                  content={
                    recent ? "Loading Recent Requests" : "Loading All Requests"
                  }
                />
              </Dimmer>
              {error && (
                <Label color="red" content={error.message} size="large" />
              )}
              {total === 0 && !loading && (
                <Header>
                  <Icon name="check circle" size="massive" color="green" /> No
                  Requests
                </Header>
              )}
              {users &&
                <ProductRequests
                  recent={recent}
                  product={data.product}
                  loading={loading}
                  fetchMore={fetchMore}
                  queryVariables={variables}
                  toastManager={toastManager}
                  history={this.props.history}
                />
              }
              {!loading && (
                <Button
                  style={{ margin: "2em 0em" }}
                  onClick={() => {
                    refetch();
                  }}
                  icon="refresh"
                  content="Reload"
                />
              )}
            </Container>
          );
        }}
      </Query>
    );
  };
}

export default withToastManager(Requests);
