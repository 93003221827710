//@flow

import createReducer from "../helpers/createReducer";
import reduxAction from "../helpers/reduxAction";
import { Record, type RecordFactory } from "immutable";

import { parseJwt } from "../helpers/jwt";

// Services
import { Auth } from "../services/auth";
const auth = new Auth();

// Actions
const actionCreator = reduxAction("auth");
const UPDATE = actionCreator("UPDATE");
const LOGOUT = actionCreator("LOGOUT");

// Reducer
const handlers = {
  [UPDATE]: (state: AuthState, action) => action.record,
  [LOGOUT]: (state: AuthState) => AuthState() // Blank
};

// State
export type AuthStateValues = {
  idToken: ?any,
  accessToken: ?string,
  scope: ?string,
  expires: ?number
};
export const AuthState: RecordFactory<AuthStateValues> = Record({
  idToken: null,
  accessToken: null,
  scope: null,
  expires: null
});
const defaultState = AuthState();
export default createReducer(defaultState, handlers);

// Action Creators
export const update = record => {
  return { type: UPDATE, record };
};

export const logout = () => {
  return { type: LOGOUT };
};

export const login = () => {
  return (dispatch, getState) => {
    console.log("login");
    auth.login();
  };
};

export const handleAuthentication = () => {
  return (dispatch, getState) => {
    return auth
      .handleAuthentication()
      .then(result => {
        const parsedAccessToken = parseJwt(result.accessToken);

        const expires = JSON.stringify(result.expiresIn * 1000 + Date.now());

        const newAuthState = getState()
          .get("auth")
          .set("idToken", parseJwt(result.idToken))
          .set("accessToken", result.accessToken)
          .set("scope", parsedAccessToken.scope)
          .set("expires", expires);

        dispatch(update(newAuthState));
      })
      .catch(error => {
        console.error(
          "handleAuthentication error: " + JSON.stringify(error, null, 3)
        );
      });
  };
};
