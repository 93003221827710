import React from "react";
import ReactDOM from "react-dom";

import { ApolloClient } from "apollo-client";
import { InMemoryCache } from "apollo-cache-inmemory";
import { HttpLink } from "apollo-link-http";
import DebounceLink from "apollo-link-debounce";

import { onError } from "apollo-link-error";
import { ApolloLink, Observable } from "apollo-link";

import { Provider as ReactProvider } from "react-redux";
import { ApolloProvider } from "react-apollo";

import "./index.css";
import AppRouter from "./containers/AppRouter";
import * as serviceWorker from "./serviceWorker";
import createStatefulStore from "./config/store";

import { GraphQLURI } from "./helpers/env";

let store;

// console.log(`Welcome process.env:${JSON.stringify(process.env, null, 3)} `);

// Reference: https://www.apollographql.com/docs/react/advanced/boost-migration/
const request = async operation => {
  const token = store.getState().getIn(["auth", "accessToken"]); // Pull access token from redux store
  const headers = {
    authorization: token ? `Bearer ${token}` : ""
  };
  operation.setContext({ headers });
};

// More ugly boilerplate from above...
const requestLink = new ApolloLink(
  (operation, forward) =>
    new Observable(observer => {
      let handle;
      Promise.resolve(operation)
        .then(oper => request(oper))
        .then(() => {
          handle = forward(operation).subscribe({
            next: observer.next.bind(observer),
            error: observer.error.bind(observer),
            complete: observer.complete.bind(observer)
          });
        })
        .catch(observer.error.bind(observer));

      return () => {
        if (handle) handle.unsubscribe();
      };
    })
);

const DEFAULT_DEBOUNCE_TIMEOUT = 100;

const client = new ApolloClient({
  link: ApolloLink.from([
    onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors) {
        console.error("graphQL:", graphQLErrors);
        return;
      }
      if (networkError) {
        console.error("network: ", networkError);
        return;
      }
    }),
    requestLink,
    new DebounceLink(DEFAULT_DEBOUNCE_TIMEOUT),
    new HttpLink({
      uri: GraphQLURI,
      credentials: "same-origin"
    })
  ]),
  cache: new InMemoryCache()
});

store = createStatefulStore(() => {
  console.log("No access token. Resetting GraphQL client store.");
  client.resetStore();
});

const App = () => (
  <ApolloProvider client={client}>
    <ReactProvider store={store}>
      <AppRouter />
    </ReactProvider>
  </ApolloProvider>
);

ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
