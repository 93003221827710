import React, { Component, createRef } from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import { graphql } from "@apollo/react-hoc";

import {
  Button,
  Container,
  Loader,
  Label,
  Header,
  Input,
  Dimmer
} from "semantic-ui-react";

import {
  GET_USERS,
  DELETE_USER,
  START_SUPPORT,
  STOP_SUPPORT
} from "../queries/users";
import { Debounce } from "../helpers/debounce";
import UserCard from "../components/UserCard";
import withMutation from "../helpers/withMutation";
import filterHOC from "../helpers/filterHOC";

const LOAD_CHUNK = 25;

class Users extends Component {
  inputRef = createRef();

  goTo = route => {
    this.props.history.push(`/${route}`);
  };

  handleSearch = Debounce(filter => {
    this.props.setFilter(filter);
  }, 250);

  handleClear = () => {
    this.handleSearch("");
    this.inputRef.current.focus();
    this.inputRef.current.inputRef.value = "";
  };

  render = () => {
    const {
      filter,
      usersResultQuery,
      startSupport,
      startSupportMutation,
      stopSupport,
      deleteUser,
      deleteUserMutation
    } = this.props;

    const {
      loading: usersResultLoading,
      fetchMore: usersResultFetchMore,
      error: usersResultError,
      usersResult
    } = usersResultQuery;

    const {
      loading: startSupportLoading,
      mutateOpts: startSupportMutateOpts
    } = startSupportMutation;

    const startSupportUserId =
      (startSupportMutateOpts &&
        startSupportMutateOpts.variables &&
        startSupportMutateOpts.variables.userId) ||
      null;

    const {
      loading: deleteUserLoading,
      mutateOpts: deleteUserMutateOpts
    } = deleteUserMutation;

    const deleteUserId =
      (deleteUserMutateOpts &&
        deleteUserMutateOpts.variables &&
        deleteUserMutateOpts.variables.userId) ||
      null;

    const totalCount = (usersResult && usersResult.totalCount) || 0;

    let errorMessage = null;
    if (usersResultError) {
      errorMessage = usersResultError.message.replace("GraphQL error: ", "");
    }

    let total =
      (usersResult && usersResult.users && usersResult.users.length) || 0;

    return (
      <Container style={{ margin: "1em 1em", paddingBottom: "2em" }}>
        <Input
          ref={this.inputRef}
          loading={usersResultLoading}
          icon="users"
          iconPosition="left"
          placeholder="Search by email..."
          onChange={event => {
            this.handleSearch(event.currentTarget.value);
          }}
          error={errorMessage != null}
          action={
            (filter.length > 0 && (
              <Button icon="x" onClick={this.handleClear} />
            )) ||
            null
          }
        />

        {errorMessage && (
          <Label color="red" content={errorMessage} size="large" />
        )}

        {usersResult && usersResult.users && (
          <React.Fragment>
            <Header>
              {total === totalCount
                ? `Users (${total})`
                : `Users (${total} of ${totalCount})`}
            </Header>
            {usersResult.users.map(u => {
              const supportUrl = u.support_join_url;
              // console.log(`user: `, u);
              const onStartSupport = ({userId, meetingId}) => {
                startSupport({
                  variables: { userId, meetingId }
                });
              };
              return (
                <UserCard
                  key={u.id}
                  user={u}
                  history={this.props.history}
                  linkToUser
                  onDelete={userId => {
                    deleteUser({
                      variables: { userId },

                      optimisticResponse: {
                        __typename: "Mutation",
                        deleteUser: {
                          _id: userId,
                          __typename: "User"
                        }
                      }
                    });
                  }}
                  onStartSupport={startSupportLoading ? null : onStartSupport}
                >
                  <Dimmer active={deleteUserLoading && deleteUserId === u.id}>
                    <Loader content="Deleting..." />
                  </Dimmer>
                  <Dimmer
                    active={startSupportLoading && startSupportUserId === u.id}
                  >
                    <Loader content="Starting Support Session..." />
                  </Dimmer>

                  <Dimmer active={supportUrl !== null}>
                    <Button
                      onClick={() => {
                        window.open(supportUrl);
                      }}
                    >
                      Join Zoom Meeting
                    </Button>
                    <Button
                      icon="x"
                      onClick={() => {
                        stopSupport({
                          variables: { userId: u.id },

                          optimisticResponse: {
                            __typename: "Mutation",
                            stopSupport: {
                              id: u.id,
                              __typename: "User",
                              support_join_url: null
                            }
                          }
                        });
                      }}
                    ></Button>
                  </Dimmer>
                </UserCard>
              );
            })}
          </React.Fragment>
        )}

        <div>
          {!usersResultLoading && totalCount !== 0 && total < totalCount && (
            <Button
              onClick={() => {
                usersResultFetchMore({
                  variables: {
                    offset: total
                  },
                  updateQuery: (prev, { fetchMoreResult }) => {
                    if (!fetchMoreResult) return prev;
                    const allUsers = [
                      ...prev.usersResult.users,
                      ...fetchMoreResult.usersResult.users
                    ];
                    return Object.assign({}, prev, {
                      usersResult: Object.assign({}, prev.usersResult, {
                        users: allUsers
                      })
                    });
                  }
                });
              }}
            >
              Load {Math.min(LOAD_CHUNK, totalCount - total)} More
            </Button>
          )}
        </div>
        <Loader
          active={filter.length === 0 && usersResultLoading}
          inline
          style={{ margin: "1.5em 0em" }}
        />
      </Container>
    );
  };
}

const mapStateToProps = state => {
  return {
    userState: state.get("users"),
    token: state.getIn(["auth", "accessToken"])
  };
};

const getUserHOC = graphql(GET_USERS, {
  name: "usersResultQuery",
  options: props => {
    const { filter } = props;
    return {
      variables: { first: LOAD_CHUNK, offset: 0, filter },
      notifyOnNetworkStatusChange: true
    };
  }
});

const startSupportHOC = withMutation(START_SUPPORT, {
  name: "startSupport"
});

const stopSupportHOC = withMutation(STOP_SUPPORT, {
  name: "stopSupport"
});

const deleteUserHOC = withMutation(DELETE_USER, {
  name: "deleteUser",
  options: props => {
    return {
      // refetchQueries={[{ query: GET_USERS, variables }]}
      notifyOnNetworkStatusChange: true
    };
  }
});

const gqlHOCs = [getUserHOC, startSupportHOC, stopSupportHOC, deleteUserHOC];

export default compose(
  filterHOC,
  ...gqlHOCs,
  connect(mapStateToProps, null)
)(Users);
