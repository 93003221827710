//@flow

import React from "react";
import { Mutation } from "react-apollo";

const withMutation = (mutation, options) => WrappedComponent =>
  class MutationOptsWrapper extends React.Component {
    state = {
      opts: {}
    };

    render() {
      return (
        <Mutation mutation={mutation} {...options}>
          {(mutate, result) => {
            const propFcn = options.props || (p => p); // Custom or Identity
            const mutateKey = options.name || "mutate";
            const resultKey = `${options.name}Mutation` || "mutation";
            const childProps = propFcn({
              [mutateKey]: mutateOpts => {
                this.setState({ opts: mutateOpts });
                return mutate(mutateOpts);
              },
              [resultKey]: { ...result, mutateOpts: this.state.opts },
              ownProps: { ...this.props }
            });
            return <WrappedComponent {...childProps} {...this.props} />;
          }}
        </Mutation>
      );
    }
  };

export default withMutation;
