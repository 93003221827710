// @flow

import AppState from "../config/app-state";

export const isExpired = expires => {
  if (expires == null) {
    return true;
  }
  return Date.now() >= expires;
};

export const getIsAuthenticated = (state: AppState): boolean => {
  const authState = state.get("auth");
  const expired = isExpired(authState.get("expires"));

  if (expired) {
    return false;
  }

  const scope = authState.get("scope");
  if (scope == null) {
    return false;
  }

  if (scope.indexOf("manage:lobby") === -1) {
    return false;
  }

  return true;
};

export const accessTokenForState = state => {
  const authState = state.get("auth");
  const expired = isExpired(authState.get("expires"));

  if (expired) {
    return null;
  }

  return authState.get("accessToken");
};
