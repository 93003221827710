// @flow

import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import { bindActionCreators } from "redux";
import { Link, NavLink, withRouter } from "react-router-dom";

import { Container, Image, Menu } from "semantic-ui-react";

import * as AuthActions from "../redux/auth";
import { getIsAuthenticated } from "../helpers/tokens";

class NavBar extends Component {
  toggleLogin = () => {
    const { logout, login, isAuthenticated, history } = this.props;

    if (isAuthenticated) {
      logout();
      history.replace("/");
    } else {
      login();
    }
  };

  render = () => {
    const { isAuthenticated } = this.props;

    return (
      <div>
        <Menu inverted stackable fluid borderless attached>
          <Container>
            <Menu.Item as={Link} to="/" header>
              <Image
                size="mini"
                src="http://d2p3vjtav3plaq.cloudfront.net/TechCheck-76%402x.png"
                style={{ marginRight: "1.5em" }}
              />
              TechCheck Portal
            </Menu.Item>
            <Menu.Item as={NavLink} exact to="/" content="Recent" />
            <Menu.Item
              as={NavLink}
              exact
              to="/requests"
              content="All Requests"
            />
            <Menu.Item as={NavLink} exact to="/users" content="Users" />
            <Menu.Item position="right" onClick={this.toggleLogin}>
              {isAuthenticated ? "Logout" : "Login"}
            </Menu.Item>
          </Container>
        </Menu>
      </div>
    );
  };
}

const mapStateToProps = state => {
  return { isAuthenticated: getIsAuthenticated(state) };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(AuthActions, dispatch);
};

export default compose(
  withRouter, // Must come first
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(NavBar);
