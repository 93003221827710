// @flow

import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Route } from "react-router-dom";

import * as AuthActions from "../redux/auth";
import { getIsAuthenticated } from "../helpers/tokens";

type Props = {
  login: () => void,
  component: Component
};

const PrivateRoute = (props: Props) => {
  const {
    isAuthenticated,
    login,
    component: Component, // Confusing syntax. This is "renaming" the variable to Component. This is not flow.
    ...rest
  } = props;

  // console.log("props: " + JSON.stringify(props, null, 3));

  return (
    <Route
      {...rest}
      render={rProps => {
        // console.log("rProps: " + JSON.stringify(rProps, null, 3));
        if (isAuthenticated) {
          return <Component {...rProps} {...props} />;
        } else {
          setTimeout(() => {
            login();
          }, 0); // Increase delay to troubleshoot auth errors
          return <div>Redirecting to login...</div>;
        }
      }}
    />
  );
};

// Use this, alternatively, if not Auth0...
// <Redirect to={{
//   pathname: '/login',
//   state: { from: props.location }
// }} />

const mapStateToProps = state => {
  return { isAuthenticated: getIsAuthenticated(state) };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(AuthActions, dispatch);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PrivateRoute);
