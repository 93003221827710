import React from "react";
import { Mutation } from "react-apollo";

import {
  Dimmer,
  Loader,
  Label,
  Button,
  Popup,
} from "semantic-ui-react";

import {
  GET_PRODUCT_WITH_REQUESTS,
  GET_PRODUCT_RECENT_REQUESTS,
  APPROVE_PRODUCT_REQUEST,
  APPROVE_TEMPORARY_PRODUCT_REQUEST,
} from "../queries/products";
import UserCard from "../components/UserCard";
import { DELETE_USER, GET_USER } from "../queries/users";

const TEMP_ACCESS_DAY = 60 * 60 * 24;
const TEMP_ACCESS_WEEK = TEMP_ACCESS_DAY * 7;

const ApprovalCard = ({product, user, variables, history}) => {
    const recent = variables.last != null;

    // console.log(`ApprovalCard product:${JSON.stringify(product, null, 3)} user:${JSON.stringify(user, null, 3)} variables:${JSON.stringify(variables, null, 3)}}}`)

    const query = recent
      ? GET_PRODUCT_RECENT_REQUESTS
      : GET_PRODUCT_WITH_REQUESTS;

    const refetchQueries = [{ query, variables }, { query: GET_USER, variables: { id: user.id } }];
    return (
      <Mutation
        key={user.id}
        mutation={DELETE_USER}
        refetchQueries={refetchQueries}
        notifyOnNetworkStatusChange
      >
        {(deleteUser, deleteUserProps) => {
          return (
            <UserCard
              key={user.id}
              user={user}
              history={history}
              onDelete={(userId) => {
                deleteUser({ variables: { userId } });
              }}
            >
              <Mutation
                mutation={APPROVE_PRODUCT_REQUEST}
                refetchQueries={refetchQueries}
                notifyOnNetworkStatusChange
              >
                {(approve, { data, loading, error }) => (
                  <React.Fragment>
                    <Dimmer active={loading}>
                      <Loader content="Approving..." />
                    </Dimmer>
                    <Dimmer active={deleteUserProps.loading}>
                      <Loader content="Deleting..." />
                    </Dimmer>
                    {error && <Label color="red">Error</Label>}
                    <Button
                      color="green"
                      onClick={() =>
                        approve({
                          variables: { productId: product.id, userId: user.id },
                          optimisticResponse: {
                            __typename: "Mutation",
                            approve: {
                              id: user.id,
                              __typename: "User",
                              pending_approval: true,
                            },
                          },
                        })
                      }
                    >
                      Approve S3C
                    </Button>
                  </React.Fragment>
                )}
              </Mutation>
              <Popup
                on="click"
                content={
                  <div>
                    <Mutation
                      mutation={APPROVE_TEMPORARY_PRODUCT_REQUEST}
                      refetchQueries={refetchQueries}
                      notifyOnNetworkStatusChange
                    >
                      {(approveTemp, { data, loading, error }) => (
                        <React.Fragment>
                          {error && <Label color="red">Error</Label>}
                          <Button
                            style={{ marginBottom: 8 }}
                            fluid
                            color="green"
                            onClick={() =>
                              approveTemp({
                                variables: {
                                  productId: product.id,
                                  userId: user.id,
                                  secondsInFuture: TEMP_ACCESS_DAY,
                                },
                              })
                            }
                          >
                            Approve for 24 Hours
                          </Button>
                        </React.Fragment>
                      )}
                    </Mutation>

                    <Mutation
                      mutation={APPROVE_TEMPORARY_PRODUCT_REQUEST}
                      refetchQueries={refetchQueries}
                      notifyOnNetworkStatusChange
                    >
                      {(approveTemp, { data, loading, error }) => (
                        <React.Fragment>
                          {error && <Label color="red">Error</Label>}
                          <Button
                            fluid
                            color="green"
                            onClick={() =>
                              approveTemp({
                                variables: {
                                  productId: product.id,
                                  userId: user.id,
                                  secondsInFuture: TEMP_ACCESS_WEEK,
                                },
                              })
                            }
                          >
                            Approve for 7 Days
                          </Button>
                        </React.Fragment>
                      )}
                    </Mutation>
                  </div>
                }
                trigger={<Button>Temporary</Button>}
              />
            </UserCard>
          );
        }}
      </Mutation>
    );
  };

  export default ApprovalCard;