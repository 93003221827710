// @flow

import { combineReducers } from "redux-immutable";

import AppState from "../config/app-state";

import auth from "./auth";
import support from "./support";

const rootReducer = combineReducers(
  {
    auth,
    support
  },
  AppState
);

export default rootReducer;
