import React, { Component } from 'react';

class NotFound extends Component {

  render = () => {
    return (
      <div>
          <p>
            Page Not Found
          </p>
      </div>
    );
  }
}

export default NotFound;
