// @flow

import React from "react";

// This is a really cool way to lift state up and out of a child component.
// This technique is required due to the react apollo HOC only having access to props
// See also https://stackoverflow.com/questions/52845894/passing-components-state-to-query-variables-inside-react-apollos-compose
const filterHOC = WrappedComponent => {
  return class FilteredWrapper extends React.Component {
    state = {
      filter: ""
    };

    render() {
      return (
        <WrappedComponent
          {...this.props}
          filter={this.state.filter}
          setFilter={filter => {
            this.setState({ filter });
          }}
        />
      );
    }
  };
};

export default filterHOC;
