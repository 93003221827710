import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import { Query } from "react-apollo";

import {
  Container,
  Dimmer,
  Loader,
  Label,
  Button,
  Header
} from "semantic-ui-react";

import { GET_USER } from "../queries/users";
import UserCard from "../components/UserCard";
import ApprovalCard from "./ApprovalCard";

class User extends Component {
  render = () => {
    const { userId } = this.props.match.params;

    return (
      <Query query={GET_USER} variables={{ id: userId }}>
        {({ loading, error, data }) => {
          const user = data && data.user;
          // console.log("user: " + JSON.stringify(user));


          let card = null;

          if(user == null && !loading){
            card = <Header>User Not Found</Header>
          } else if(user != null && user.requestedProducts && user.requestedProducts.length === 1){
            card = <ApprovalCard history={this.props.history} product={user.requestedProducts[0]} user={user} variables={{ id: user.requestedProducts[0].id }} />;
          } else if (user != null){
            card = <UserCard user={user} />
          }

          return (
            <Container style={{ marginTop: 20 }}>
              <Dimmer active={loading}>
                <Loader content={"Loading"} />
              </Dimmer>

              <Button
                onClick={() => this.props.history.push('/users')}
                content="Show All"
                style={{marginBottom: 10}}
              />

              {error && <Label color="red" content="Error" size="large" />}
              {card}
              
            </Container>
          );
        }}
      </Query>
    );
  };
}

const mapStateToProps = state => {
  return {};
};

export default compose(
  connect(
    mapStateToProps,
    null
  )
)(User);
