// @flow

import { createStore, compose, applyMiddleware } from "redux";
import thunk from "redux-thunk";

import rootReducer from "../redux";
import AppState from "./app-state";
import { AuthState } from "../redux/auth";
import logger from "../helpers/redux-logging";

const createStatefulStore = (onEmptyAuth: () => void) => {
  const devToolsCompose = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;
  const composer = devToolsCompose || compose;

  const authStateJSON = JSON.parse(localStorage.getItem("auth_state"));

  const rehydratedAuth = AuthState(authStateJSON);
  var appState = AppState().set("auth", rehydratedAuth);

  const store = createStore(
    rootReducer,
    appState,
    composer(applyMiddleware(thunk, logger))
  );

  let currentAuthState = null;

  store.subscribe(() => {
    // console.log("store.subscribe state: " + JSON.stringify(store.getState()));

    const previousAuthState = currentAuthState;
    const newAuthState = store.getState().get("auth");

    if (!newAuthState.equals(previousAuthState)) {
      if (newAuthState.get("accessToken") == null) {
        onEmptyAuth();
      }
      // console.log('store.subscribe updating auth state');
      currentAuthState = newAuthState;
      localStorage.setItem("auth_state", JSON.stringify(newAuthState));
    }
  });

  return store;
};

export default createStatefulStore;
