import React, { Component } from 'react';

class AuthCallback extends Component {

  render = () => {
    return (
      <div>
          <h2>Logging in...</h2>
      </div>
    );
  }
}

export default AuthCallback;
