import React from "react";
import CopyToClipboard from "react-copy-to-clipboard";

import {
  Loader,
  Button,
  Header,
  Popup,
} from "semantic-ui-react";

import ApprovalCard from "./ApprovalCard";

const LOAD_CHUNK = 25;

const ProductRequests = (options) => {
    const {
      product,
      loading,
      fetchMore,
      queryVariables,
      recent,
      toastManager,
      history
    } = options;

    const requests = product.requests || product.recentRequests;
    const { totalCount } = requests;
    let total = (requests && requests.users.length) || 0;

    let emails = [];
    if (requests && requests.users.length) {
      emails = requests.users.map((u) => u.email).join(",\n");
    }

    const innerTotal =
      total === totalCount ? `${total}` : `${total} of ${totalCount}`;

    const emailPopup = (
      <Popup
        hoverable
        on="hover"
        position="right center"
        trigger={<span>({innerTotal})</span>}
      >
        <CopyToClipboard text={emails}>
          <Button
            primary
            basic
            onClick={() => {
              toastManager.add(`Copied ${total} email addresses.`, {
                appearance: "success",
                autoDismiss: true,
                pauseOnHover: false,
              });
            }}
          >
            Copy Email Addresses
          </Button>
        </CopyToClipboard>
      </Popup>
    );

    return (
      <div key={product.id} style={{ marginTop: "1em" }}>
        <Header style={{ marginBottom: 0 }}>
          {product.id} -{recent ? " Recent " : " All "}Requests {emailPopup}
        </Header>
        <Header size="small" style={{ opacity: 0.4, marginTop: 0 }}>
          Sorted {recent ? "Newest First" : "Oldest First"}
        </Header>
        {requests.users
          .filter((user) => !user.pending_approval)
          .map((user) =>
            <ApprovalCard key={`approval-${user.id}`} history={history} product={product} user={user} variables={queryVariables} />
          )}

        <div>
          {!loading && totalCount !== 0 && total < totalCount && (
            <Button
              onClick={() => {
                fetchMore({
                  variables: {
                    offset: total,
                  },
                  updateQuery: (prev, { fetchMoreResult, variables }) => {
                    // console.log(
                    //   "variables: " + JSON.stringify(variables, null, 3)
                    // );
                    // console.log("prev: " + JSON.stringify(prev, null, 3));
                    // console.log(
                    //   "fetchMoreResult: " +
                    //     JSON.stringify(fetchMoreResult, null, 3)
                    // );

                    if (!fetchMoreResult) return prev;
                    const allRequestsUsers = [
                      ...prev.product.requests.users,
                      ...fetchMoreResult.product.requests.users,
                    ];

                    let newResult = Object.assign({}, prev);
                    newResult.product.requests.users = allRequestsUsers;
                    return newResult;
                  },
                });
              }}
            >
              Load {Math.min(LOAD_CHUNK, totalCount - total)} More
            </Button>
          )}
        </div>
        <Loader active={loading} inline style={{ margin: "1.5em 0em" }} />
      </div>
    );
  };

  export default ProductRequests;