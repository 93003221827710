import gql from "graphql-tag";

export const USERS_INFO_FRAGMENT = gql`
  fragment UsersInfo on User {
    id
    created_at
    last_login
    admin
    name
    email
    email_verified
    company
    pending_approval
    approvals {
      productName
      bySelf
      approved_by_name
      approved_by_email
      approved_on
    }
    support_join_url
    approved_until
    requestedProducts {
      id
    }
  }
`;

export const GET_USERS = gql`
  ${USERS_INFO_FRAGMENT}
  query GetUsers($first: Int, $offset: Int, $filter: String) {
    usersResult(first: $first, offset: $offset, filter: $filter) {
      users {
        ...UsersInfo
      }
      totalCount
    }
  }
`;

export const GET_USER = gql`
  ${USERS_INFO_FRAGMENT}
  query GetUser($id: ID!) {
    user(id: $id) {
      ...UsersInfo
    }
  }
`;

export const DELETE_USER = gql`
  mutation deleteUser($userId: ID!) {
    deleteUser(userId: $userId)
  }
`;

export const START_SUPPORT = gql`
  mutation startSupport($userId: ID!, $meetingId: String!) {
    startSupport(userId: $userId, meetingId: $meetingId)
  }
`;

export const STOP_SUPPORT = gql`
  mutation stopSupport($userId: ID!) {
    stopSupport(userId: $userId)
  }
`;
