import auth0 from "auth0-js";
import axios from 'axios';

import { AUTH0_DOMAIN, AUTH0_CLIENT_ID, AuthCallback } from "../helpers/env";

export class Auth {
  auth0 = new auth0.WebAuth({
    domain: AUTH0_DOMAIN,
    clientID: AUTH0_CLIENT_ID,
    redirectUri: AuthCallback,
    responseType: "token id_token",
    scope: "openid email offline_access manage:lobby",
    audience: "https://app-admin.api.semireg.com"
  });

  login = () => {
    this.auth0.authorize();
  };

  handleAuthentication = () => {
    return new Promise((resolve, reject) => {
      this.auth0.parseHash((err, authResult) => {
        if (authResult && authResult.accessToken && authResult.idToken) {
          resolve(authResult);
        } else if (err) {
          reject(err);
        }
      });
    });
  };
}

export const resetPassword = (email: string) => {
  const url = `https://${AUTH0_DOMAIN}/dbconnections/change_password`
  const payload = { email, connection: "Username-Password-Authentication", client_id: AUTH0_CLIENT_ID };

  console.log(`resetPassword url:${url} payload:${JSON.stringify(payload, null, 3)}`);

  return axios
  .post(url, payload)
  .then((response) => {
    return true;
  })
  .catch((error) => {
    console.error(`error sending email: ${JSON.stringify(error, null, 3)}`)
    alert(`Error sending email.`);
  })
}