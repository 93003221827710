export const API_PROD = process.env.API_ENV === "production";
export const SERVER_DEPLOYED = process.env.NODE_ENV === "production";
export const AUTH0_CLIENT_ID = process.env.AUTH0_CLIENT_ID;
export const AUTH0_DOMAIN = process.env.AUTH0_DOMAIN;

// console.log("process.env: " + JSON.stringify(process.env, null, 3));

export const BaseURL = SERVER_DEPLOYED
  ? `https://techcheck${API_PROD ? "" : "-test"}.parker.semireg.com`
  : "http://localhost:3000";

export const AuthCallback = `${BaseURL}/callback`;

export const GraphQLURI = SERVER_DEPLOYED
  ? `https://techcheck-api${API_PROD ? "" : "-test"}.parker.semireg.com/graphql`
  : "http://localhost:8080/graphql";

// console.log(
//   `AUTH0_DOMAIN:${AUTH0_DOMAIN} BaseURL:${BaseURL} AuthCallback:${AuthCallback} GraphQLURI:${GraphQLURI}`
// );
