//@flow

// NOTE: THIS FILE IS DEFUNCT
// HOWEVER, IT IS STAYING BECAUSE OF FUTURE BOILERPLATE

import createReducer from "../helpers/createReducer";
import reduxAction from "../helpers/reduxAction";
import { Map, Record, type RecordFactory } from "immutable";

// Actions
const actionCreator = reduxAction("support");
const UPDATE = actionCreator("UPDATE");

// Reducer
const handlers = {
  [UPDATE]: (state: SupportState, action) => {
    return action.record;
  }
};

// State
export type SupportStateValues = {
  userSupportJoinURLs: Map<string, string>
};
export const SupportState: RecordFactory<SupportStateValues> = Record({
  userSupportJoinURLs: Map()
});
const defaultState = SupportState();
export default createReducer(defaultState, handlers);

// Action Creators
export const update = record => {
  return { type: UPDATE, record };
};

export const setUserSupport = (user: string, url: string) => {
  return (dispatch, getState) => {
    const newSupportState = getState()
      .get("support")
      .setIn(["userSupportJoinURLs", user], url);

    dispatch(update(newSupportState));
  };
};
